import DeviceService from 'src/service/device-service';

function formatDateForMac(dateStr) {
  if (DeviceService.isMacOs() || DeviceService.isAppleDevice()) {
    const dateArr = dateStr.split(' ');

    dateArr[1] = `T${dateArr[1]}Z`;

    return dateArr.join('');
  }

  return dateStr;
}

export default function (badges = null) {
  if (!badges) {
    return null;
  }

  if ((badges instanceof Array) && badges.length) {
    return badges.filter((badge) => {
      if (!badge.expired_at) {
        return true;
      }
      const formatDate = formatDateForMac(badge.expired_at);
      const date = new Date(formatDate);

      return date > Date.now();
    });
  }

  if ((badges instanceof Object) && Object.keys(badges).length) {
    return Object.entries(badges)
      .filter((entry) => {
        if (!entry[1].expired_at) {
          return true;
        }
        const formatDate = formatDateForMac(entry[1].expired_at);
        const date = new Date(formatDate);

        return date > Date.now();
      })
      .reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});
  }
}
